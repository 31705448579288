<script>
import UserInfo from "@/components/Authorization/UserInfo.vue";
export default {
  components: {
    UserInfo,
  },
};
</script>

<template>
  <UserInfo />
</template>

<style></style>
