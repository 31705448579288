<script>
import PatchProduct from "@/components/Product/PatchProduct.vue";

export default {
  components: {
    PatchProduct,
  },
};
</script>

<template>
  <PatchProduct />
</template>

<style scoped></style>
