<script>
import DetailProduct from "@/components/Product/DetailProduct.vue";

export default({
  name: 'DetailedPage',
  components: { DetailProduct },
})
</script>

<template>
  <DetailProduct/>
</template>

<style scoped>

</style>