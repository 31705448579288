<script>
import RegisterForm from "@/components/Authorization/RegisterForm.vue";
export default {
  name: "RegisterComponent",
  components: {
    RegisterForm,
  },
};
</script>

<template>
  <RegisterForm />
</template>

<style scoped></style>
