<script>
import OAuthRegisterForm from "@/components/Authorization/OAuthRegisterForm.vue";
export default {
  components: {
    OAuthRegisterForm,
  },
  name: "OAuthRegister",
};
</script>

<template>
  <OAuthRegisterForm />
</template>
