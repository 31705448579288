<script>
import MakeProduct from "@/components/Product/MakeProduct.vue";
export default {
  components: {
    MakeProduct,
  },
};
</script>

<template>
  <MakeProduct />
</template>

<style></style>
