<script>
import ProductList from "@/components/Product/ProductList.vue";


export default({
  name: 'EntireProduct',
  components: { ProductList },
})

</script>

<template>
  <ProductList />
</template>

<style scoped>

</style>