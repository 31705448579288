<template>
  <div>
    <CompanyList />
  </div>
</template>

<script>
import CompanyList from "@/components/Company/CompanyList.vue";

export default {
  components: {
    CompanyList,
  },
};
</script>
